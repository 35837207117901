// 会员指南
import dayjs from 'dayjs'
import session from '@/common/session'
import { makeAutoObservable } from 'mobx'
import { apis } from '@/common/http-client'
import { RESPONSE_CODE } from '@/config'
import { Toast } from '@/components/Toast'
import { element } from '@/common/mall-tracking'
import tag1 from '@/assets/member-guide/tag-1.png'
import tag2 from '@/assets/member-guide/tag-2.png'
import tag3 from '@/assets/member-guide/tag-3.png'
import tag4 from '@/assets/member-guide/tag-4.png'
import tag5 from '@/assets/member-guide/tag-5.png'
import tag6 from '@/assets/member-guide/tag-6.png'
import tag7 from '@/assets/member-guide/tag-7.png'
import tag8 from '@/assets/member-guide/tag-8.png'
import { ElementType } from '@/common/mall-tracking/types'
import tag1_s from '@/assets/member-guide/tag-1-active.png'
import tag2_s from '@/assets/member-guide/tag-2-active.png'
import tag3_s from '@/assets/member-guide/tag-3-active.png'
import tag4_s from '@/assets/member-guide/tag-4-active.png'
import tag5_s from '@/assets/member-guide/tag-5-active.png'
import tag6_s from '@/assets/member-guide/tag-6-active.png'
import tag7_s from '@/assets/member-guide/tag-7-active.png'
import tag8_s from '@/assets/member-guide/tag-8-active.png'
import ShareBg from '@/assets/member-guide/share-data-bg-v1.png'
import ShareCardBg from '@/assets/member-guide/share-card-bg.png'
import { navigateToByNative } from '@/common/jsBridge/nativeApp'
import { getAppDataByBridge } from '@/common/jsBridge/jsBridgeApi'
import { CreateCanvas } from '@/utils/create-canvas/CreateCanvas'
import Big from 'big.js'
import { getHelpCenterContent, isAndroid } from '@/utils'

export interface MemberGuideItem {
  id: string
  name: string
  titleName: string // 主标题
  titleNameEn: string // 英文主标题
  subTitleName: string // 副标题
  subTitleNameEn: string // 副标题英文
  headImg: string // 图片
  headImgEn: string // 图片英文
  jumpLinkVo: any // 跳转链接
}
export interface MemberImageTextAskType {
  id: string
  titleName: string // 主标题
  titleNameEn: string // 英文主标题
  subTitleName: string // 副标题
  subTitleNameEn: string // 副标题英文
  headImg: string // 图片
  headImgEn: string // 图片英文
  jumpLinkVo: any // 跳转链接
}
export interface MemberBulletScreenAskType {
  id: string
  titleName: string // 主标题
  titleNameEn: string // 英文主标题
  jumpLinkVo: any // 跳转链接
}

export interface CardInfoType {
  memType: number //  会员类型
  cardType: number /** 会员卡类型 */
  cardStatus: number // 会员卡状态
  renewEnable: boolean // 是否展示续费按钮
  hideEnable: boolean // 是否展示账单模块
  copy: string // 文案字段
  membershipDays: number // 入会天数
  saveMoneyAmount: number // 省钱金额
  annualEnable: boolean // 是否展示年度报告
  saveMoneyAmountChangeEnable: boolean // 是否展示续费金额变化
}

export interface SamsSaveMoneyInfoType {
  saveMoneyAmount: string // 省钱金额
  longTermProductNumber: string // 购买商品数量
  longTermProductAmount: string // 购买商品金额
  plusEquitiesAmount: string // 卓越专享权益金额
  couponNumber: string // 优惠券数量
  couponAmount: string // 优惠券金额
  rewardAmount: string // 山姆金金额
  startTime: string
  endTime: string
}

export interface memberGuideOrderDataVoInfoType {
  totalCount: string
  onlineCount: string
  offlineCount: string
  goodsTotalCount: string
  goodsOnlineCount: string
  goodsOfflineCount: string
}

export interface memberGuideActivityDataInfoType {
  tastingOfficerActivityNum: string
  offineEventActivityNum: string
}

export interface topProductInfoType {
  number: string
  productName: string
  imgUrl: string[]
}

export interface categoryInfoType {
  categoryName: string
  categoryRatio: string
}

export enum SHARE_DATA_TYPE {
  ALLCATEGORY = 0,
  /** 主卡年度省钱金额/会员费 >=2 */
  SAVEMONEY = 1,
  /** 主卡年度省钱金额/会员费 >=1 */
  SAVEMONEY2 = 2,
  /** 亲友卡年度省钱金额/260 >=1 */
  FAMILY = 3,
  /** 家具 */
  FURNITURE = 4,
  /** 户外 */
  OUTDOORS = 5,
  /** 营养保健 */
  NUTRITION = 6,
  /** 宠物 */
  PET = 7,
  /** 美妆 */
  SKIN = 8,
  /** 母婴 */
  MOTHER = 9,
  /** 酒水 */
  BEVERAGE = 10,
  /** 零食 */
  SNACK = 11,
  /** 烘焙 */
  BAKING = 12,
  /** 海鲜 */
  SEAFOOD = 13,
  /** 水果 */
  FRUIT = 14,
  /** app订单 */
  APPORDER = 15,
  /** 门店订单 */
  STORTORDER = 16,
  /** 未命中 */
  MISSING = 17,
  /** 兜底 */
  DEFAULT = 18
}

const noShopInLine = [SHARE_DATA_TYPE.APPORDER, SHARE_DATA_TYPE.STORTORDER]

const saveLableList = [
  SHARE_DATA_TYPE.SAVEMONEY,
  SHARE_DATA_TYPE.SAVEMONEY2,
  SHARE_DATA_TYPE.FAMILY
]

const DEFAULT_TAGS = [
  {
    src: tag1,
    title: '有被戳到',
    select: tag1_s
  },
  {
    src: tag2,
    title: '赞一个',
    select: tag2_s
  },
  {
    src: tag3,
    title: '不愧是我',
    select: tag3_s
  },
  {
    src: tag4,
    title: '惊呆了',
    select: tag4_s
  },
  {
    src: tag5,
    title: '炫耀一下',
    select: tag5_s
  },
  {
    src: tag6,
    title: '满满回忆',
    select: tag6_s
  },
  {
    src: tag7,
    title: '有点惊喜',
    select: tag7_s
  },
  {
    src: tag8,
    title: '有点意思',
    select: tag8_s
  }
]

// 缩放比例
const scale = 2 / 3

export interface memberGuideShareDataInfoType {
  type: string // 对用得分类
  membershipDays: string // 入会天数
  startTime: string // 周期开始时间
  endTime: string // 周期结束时间
  firstName: string // 会员名
  lastName: string // 会员姓
  orderTotalCount: string // 订单总数量,包含线上及线下
  goodsTotalCount: string // 商品总数量,包含线上及线下
  onlineCount: string // 线上订单
  offlineCount: string // 线下订单
  saveMoneyAmount: string // 省钱金额
  duesMultiple: string // 倍速
  shareTitle: string // 分享标题
  shareCategoryName: string // 分享类目名称
  shareCategoryRatio: string // 类目比例
  shareCopy: string // 分享内容文案
  nikeName: string //  用户昵称
}

export interface ShareInfoType {
  samsSaveMoneyInfo: SamsSaveMoneyInfoType // 省钱金额详细
  memberGuideOrderDataVoInfo: memberGuideOrderDataVoInfoType // 订单明细
  memberGuideActivityDataInfo: memberGuideActivityDataInfoType // 活动明细
  topProductInfos: topProductInfoType[] // 顶部商品信息
  categoryInfos: categoryInfoType[] // 分类信息
  memberGuideShareDataInfo: memberGuideShareDataInfoType // 分享数据
  annualEnable: boolean // 是否可以分享
}

const isQA = (import.meta.env as any).MODE === 'qa'

class MemberGuideStore {
  /** 活动详情 */

  tagList = DEFAULT_TAGS
  /** 用户信息 */
  cardInfo: Record<string, any> = {}
  /** 分享数据内容 */
  shareInfo: Record<string, any> = {
    topProductInfos: [],
    categoryInfos: [],
    samsSaveMoneyInfo: {
      longTermProductNumber: '0',
      longTermProductAmount: '0',
      plusEquitiesAmount: '0',
      couponNumber: '0',
      couponAmount: '0',
      rewardAmount: '0'
    },
    memberGuideOrderDataVoInfo: {
      onlineCount: 0,
      offlineCount: 0
    },
    memberGuideActivityDataInfo: {
      OffineEventActivityNum: 0,
      tastingOfficerActivityNum: 0
    },
    memberGuideShareDataInfo: {
      type: 18,
      orderTotalCount: '0',
      goodsTotalCount: '0',
      onlineCount: '0',
      offlineCount: '0',
      saveMoneyAmount: '0',
      duesMultiple: '0',
      shareTitle: '',
      membershipDays: '0',
      shareCategoryName: '',
      shareCategoryRatio: '',
      shareCopy: '',
      startTime: '',
      endTime: '',
      nikeName: ''
    }
  }
  /** 状态栏高度 */
  statusBarHeight = 0
  /** 周期报告是否展开 */
  isExpand = false
  /** 周期报告是否展开 */
  agreementVisibleModal = false
  /** 是否同意授权 */
  agreementCheck = false
  /** 是否可以分享 */
  canShare = true
  /** 系统语言 */
  lang = session.getSystemLanguage()
  /** 图片轮播广告 */
  memberGuides: MemberGuideItem[] = []
  /** 弹幕问题 */
  memberBulletScreenAsks: MemberBulletScreenAskType[] = []
  /** 图文问题 */
  memberImageTextAsks: MemberImageTextAskType[] = []
  /** 页面loading */
  loading = false
  /** 页面展示空白 */
  isError = false

  transtionModalInfo = {
    title: '',
    contentCopy: '',
    bottomCopy: '',
    buttonCopy: ''
  }
  transtionModalVisible = false

  /** 错误信息 */
  errorMsg = ''
  tipContentVisibleModal = false
  tipContentModalTitle = ''
  tipContentModalContent = ''

  constructor() {
    makeAutoObservable(this)
  }

  async setStatusBarHeight() {
    const { statusBarHeight } = await getAppDataByBridge(['statusBarHeight'])
    this.statusBarHeight = Number(statusBarHeight)
  }
  updateCardInfo(newData: any) {
    this.cardInfo = {
      ...this.cardInfo,
      ...newData
    }
  }

  updatetranstionModalInfo(newData: any) {
    this.transtionModalInfo = {
      ...this.transtionModalInfo,
      ...newData
    }
  }
  updateShareInfo(newData: any) {
    this.shareInfo = {
      ...this.shareInfo,
      ...newData
    }
  }
  setIsExpand() {
    this.isExpand = !this.isExpand
  }
  setLoading(flag: boolean) {
    this.loading = flag
  }
  setIsError(flag: boolean) {
    this.isError = flag
  }
  updateErrorMsg(msg: string) {
    this.errorMsg = msg
  }
  setCanShare(flag: boolean) {
    this.canShare = flag
  }
  updateMemberGuides(list: MemberGuideItem[]) {
    this.memberGuides = list
  }
  updateMemberBulletScreenAsks(list: MemberBulletScreenAskType[]) {
    this.memberBulletScreenAsks = list
  }
  updateMemberImageTextAsks(list: MemberImageTextAskType[]) {
    this.memberImageTextAsks = list
  }
  setAgreementVisibleModal(flag: boolean) {
    this.agreementVisibleModal = flag
  }
  setTranstionModalVisible(flag: boolean) {
    this.transtionModalVisible = flag
  }
  setTipContentVisibleModal(flag: boolean) {
    this.tipContentVisibleModal = flag
  }
  changeAgreementCheck(flag: boolean) {
    this.agreementCheck = flag
  }

  async queryMemberSaveMoney() {
    try {
      const { code, data, msg } = await apis.memberGuide.queryMemberSaveMoney()
      if (code === RESPONSE_CODE.SUCCESS) {
        const {
          saveMoneyAmount,
          annualEnable,
          saveMoneyAmountChangeEnable,
          hideEnable,
          startTime,
          endTime
        } = data as any
        this.updateCardInfo({
          saveMoneyAmount,
          annualEnable,
          saveMoneyAmountChangeEnable,
          hideEnable,
          startTime,
          endTime
        })
        this.queryShareInfo()
        this.queryTipContent()
      } else {
        this.setIsError(true)
        this.updateErrorMsg(msg || '系统异常')
      }
    } catch (e) {
      this.updateErrorMsg('系统异常')
      this.setIsError(true)
    }
  }

  async fetchMemberData() {
    // 开始加载
    this.setLoading(true)
    this.queryMemberSaveMoney()
    try {
      const [guidelineResult, guideInfoResult] = await Promise.all([
        apis.memberGuide.queryMemberGuideline(),
        apis.memberGuide.querymemberGuideInfo()
      ])
      // 处理 guidelineResult 轮播 和弹幕广告
      if (guidelineResult.code === RESPONSE_CODE.SUCCESS) {
        const {
          memberGuides,
          memberBulletScreenAsks,
          memberImageTextAsks
        } = guidelineResult.data as any
        this.updateMemberGuides(memberGuides)
        memberGuides.forEach((item: MemberGuideItem) => {
          const img = new Image()
          img.src = item?.headImg
        })
        this.updateMemberBulletScreenAsks(memberBulletScreenAsks)
        this.updateMemberImageTextAsks(memberImageTextAsks)
      }

      // 处理 guideInfoResult 欢迎文案和天数
      if (guideInfoResult.code === RESPONSE_CODE.SUCCESS) {
        const { cardInfo, renewEnable, membershipDays, copy } = guideInfoResult.data
        this.updateCardInfo({
          ...cardInfo,
          renewEnable,
          membershipDays,
          copy
        })
      } else {
        this.updateErrorMsg(guideInfoResult?.msg || '系统异常')
        this.setIsError(true)
        this.setLoading(false)
        return
      }
      this.setLoading(false)
    } catch (e) {
      this.updateErrorMsg('系统异常')
      this.setIsError(true)
      this.setLoading(false)
    }
  }

  async queryUserIsAgree() {
    try {
      const { code, data } = await apis.memberGuide.queryUserIsAgree({
        agreementType: 15
      })
      Toast.hide()
      if (code === RESPONSE_CODE.SUCCESS) {
        this.changeAgreementCheck(data)
      }
    } catch (e) {
      console.log(e)
    }
  }

  async saveUserIsAgree() {
    try {
      const { code, data } = await apis.memberGuide.saveAgreement({
        agreementType: 15
      })
      Toast.hide()
      if (code === RESPONSE_CODE.SUCCESS) {
        this.changeAgreementCheck(true)
        this.setAgreementVisibleModal(false)
      } else {
        Toast.text('保存失败')
      }
    } catch (e) {
      console.log(e)
    }
  }

  async queryTipContent() {
    try {
      const res = await getHelpCenterContent(isQA ? '184' : 216)
      if (res) {
        const { content, title } = res
        if (content && title) {
          this.tipContentModalTitle = title
          this.tipContentModalContent = content
        }
      } else {
        this.setIsError(true)
        this.updateErrorMsg('系统异常')
      }
    } catch (e) {
      this.updateErrorMsg('系统异常')
      this.setIsError(true)
    }
  }

  async queryShareInfo() {
    try {
      const { code, data, msg } = await apis.memberGuide.queryShareInfo()

      if (code === RESPONSE_CODE.SUCCESS) {
        const {
          samsSaveMoneyInfo,
          memberGuideOrderDataVoInfo,
          memberGuideActivityDataInfo,
          memberGuideShareDataInfo,
          topProductInfos,
          annualEnable,
          categoryInfos
        } = data as any

        this.updateShareInfo({
          samsSaveMoneyInfo,
          memberGuideOrderDataVoInfo,
          memberGuideActivityDataInfo,
          memberGuideShareDataInfo,
          topProductInfos,
          annualEnable,
          categoryInfos
        })
      } else {
        this.setIsError(true)
        this.updateErrorMsg(msg || '系统异常')
      }
    } catch (e) {
      this.updateErrorMsg('系统异常')
      this.setIsError(true)
    }
  }

  async queryTistionModal() {
    try {
      const { code, data, msg } = await apis.memberGuide.queryTistionModal()
      if (code === RESPONSE_CODE.SUCCESS) {
        const {
          title = '',
          contentCopy = '',
          bottomCopy = '',
          popEnable = false,
          buttonCopy = ''
        } = data || {}
        this.setTranstionModalVisible(popEnable)
        this.updatetranstionModalInfo({
          title,
          contentCopy,
          bottomCopy,
          buttonCopy
        })
      }
    } catch (e) {}
  }

  // 跳转试吃车
  jumpToLineActivity() {
    element(
      {
        type: ElementType.Tap,
        elementId: 'member_activity_module',
        eventName: 'sam_h5_personalCenter_member_activity_module_tap',
        pageType: '个人中心页"',
        pageTitle: '会员指南详情',
        pageId: 'default'
      },
      {
        event_value: {
          shopping_type: '线上试吃车'
        }
      }
    )
    navigateToByNative(
      `sams://samsDiscoverDecoration?urlLink=https://decoration-${
        isQA ? 'qa-' : ''
      }sams.walmartmobile.cn/taste-officer-list.html`
    )
  }

  // 跳转活动日历
  jumpToStoreActivity(otherTag = true) {
    const common = {
      type: ElementType.Tap,
      pageType: '个人中心页"',
      pageTitle: '会员指南详情',
      pageId: 'default'
    }
    element(
      {
        elementId: otherTag ? 'member_activity_module' : 'play_sam_module',
        eventName: otherTag
          ? 'sam_h5_personalCenter_member_activity_module_tap'
          : 'sam_h5_personalCenter_play_sam_module_tap',
        ...common
      },
      {
        event_value: {
          shopping_type: otherTag ? '门店活动' : '会员活动日历'
        }
      }
    )
    navigateToByNative(
      `sams://h5?url=https://m-${isQA ? 'qa-' : ''}sams.walmartmobile.cn/sams/offline-activity/list`
    )
  }

  // 跳转APP购物 门店购物
  jumpShop(type: string) {
    element(
      {
        type: ElementType.Tap,
        elementId: 'member_shopping_module',
        eventName: 'sam_h5_personalCenter_member_shopping_module_tap',
        pageType: '个人中心页"',
        pageTitle: '会员指南详情',
        pageId: 'default'
      },
      {
        event_value: {
          shopping_type: type === 'app' ? 'APP购物' : '门店购物'
        }
      }
    )
    navigateToByNative(`sams://my_order?titleSelectedIndex=${type === 'app' ? 0 : 1}`)
  }
  // 跳转我的优惠券
  jumpMyCoupon() {
    element(
      {
        type: ElementType.Tap,
        elementId: 'member_saving_type',
        eventName: 'sam_h5_personalCenter_member_saving_type_tap',
        pageType: '个人中心页"',
        pageTitle: '会员指南详情',
        pageId: 'default'
      },
      {
        event_value: {
          saving_type: '使用优惠券'
        }
      }
    )
    navigateToByNative(`sams://coupon`)
  }

  jumpSavePage() {
    element(
      {
        type: ElementType.Tap,
        elementId: 'member_saving_type',
        eventName: 'sam_h5_personalCenter_member_saving_type_tap',
        pageType: '个人中心页"',
        pageTitle: '会员指南详情',
        pageId: 'default'
      },
      {
        event_value: {
          saving_type: '卓越专属权益'
        }
      }
    )

    navigateToByNative(
      `${import.meta.env.VITE_BENEFIT_URL}/app/money-saving-calculator/?type=currentCycle`,
      {
        isFullScreen: 0
      }
    )
  }
  // 跳转探店
  jumpVisitStore() {
    element(
      {
        type: ElementType.Tap,
        elementId: 'play_sam_module',
        eventName: 'sam_h5_personalCenter_play_sam_module_tap',
        pageType: '个人中心页"',
        pageTitle: '会员指南详情',
        pageId: 'default'
      },
      {
        event_value: {
          shopping_type: '探店指南'
        }
      }
    )
    navigateToByNative(`sams://visit_store`)
  }
  // 跳转山姆金中心
  jumpCash() {
    element(
      {
        type: ElementType.Tap,
        elementId: 'member_saving_type',
        eventName: 'sam_h5_personalCenter_member_saving_type_tap',
        pageType: '个人中心页"',
        pageTitle: '会员指南详情',
        pageId: 'default'
      },
      {
        event_value: {
          saving_type: '山姆回馈金'
        }
      }
    )
    navigateToByNative(
      `sams://h5?url=https://sams-benefit-h5${isQA ? '-qa' : ''}.walmartmobile.cn/app/cash-detail/`
    )
  }

  jumpActivityRule() {
    element({
      type: ElementType.Tap,
      elementId: 'click_activity_rule',
      eventName: 'sam_h5_personalCenter_memberGuide_click_activity_rule_tap',
      pageType: '个人中心页"',
      pageTitle: '会员指南详情',
      pageId: 'default'
    })
    navigateToByNative(
      `sams://h5?url=${import.meta.env.VITE_DOMAIN}/common/help-center/${isQA ? '161' : '204'}`
    )
  }

  jumpAgreeRule() {
    navigateToByNative(
      `sams://h5?url=${import.meta.env.VITE_DOMAIN}/common/help-center/${isQA ? '133' : '205'}`
    )
  }

  jumpMoreGoods(noElement = false) {
    if (!noElement) {
      element(
        {
          type: ElementType.Tap,
          elementId: 'member_saving_type',
          eventName: 'sam_h5_personalCenter_member_saving_type_tap',
          pageType: '个人中心页"',
          pageTitle: '会员指南详情',
          pageId: 'default'
        },
        {
          event_value: {
            saving_type: '购买长期降价商品'
          }
        }
      )
    }
    navigateToByNative(
      `sams://decoration_preview?pageContentId=${
        isQA ? '2372499742006614806' : '2266992147796518934'
      }`
    )
  }

  generateShareImage(canvas: CreateCanvas) {
    const { memberGuideShareDataInfo } = this.shareInfo
    const { shareCopy, type } = memberGuideShareDataInfo || {}
    // 分享文案
    const [samsText1, samsText2] = shareCopy.split('\n')
    const textDefaultOpt = {
      fontFamily: 'PingFang SC-Medium, PingFang SC',
      fillStyle: '#222427',
      fontWeight: 500,
      textAlign: 'center',
      fontSize: 26,
      x: 375
    }
    // 内容卡片默认高度
    let samsTextBgy = 804
    let samsText1y = 880
    let samsText2y = 916

    // 处理saveLableList的情况
    if (saveLableList.includes(type)) {
      samsTextBgy = 836
      samsText1y = 916
      samsText2y = 952
    } else {
      // 使用switch来处理其他基于type的直接比较
      switch (type) {
        case SHARE_DATA_TYPE.STORTORDER:
          samsTextBgy = 846
          samsText1y = 926
          samsText2y = 962
          break
        case SHARE_DATA_TYPE.MISSING:
          samsTextBgy = 669
          samsText1y = 745
          samsText2y = 781
          break
        case SHARE_DATA_TYPE.FAMILY:
          samsTextBgy = 796
          samsText1y = 876
          samsText2y = 912
          break
        case SHARE_DATA_TYPE.DEFAULT: // 注意这里应该是DEFAULT，而不是DEFALUT
          samsTextBgy = 624
          samsText1y = 704
          samsText2y = 740
          break
        default:
          // 如果没有匹配到任何case，则保持默认高度（这里已经是默认值了）
          // 或者可以设置一个错误处理逻辑
          break
      }
    }

    // 现在可以使用samsTextBgy, samsText1y, samsText2y了

    canvas.drawImage(ShareCardBg, {
      x: 130,
      y: samsTextBgy,
      width: 736 * scale,
      height: 321 * scale
    })

    // 陪伴
    samsText1 &&
      canvas.drawText(samsText1, {
        ...textDefaultOpt,
        y: samsText1y
      })
    samsText2 &&
      canvas.drawText(samsText2, {
        ...textDefaultOpt,
        y: samsText2y
      })
  }

  // 提示文案
  generateHint(canvas: CreateCanvas) {
    const { memberGuideShareDataInfo } = this.shareInfo || {}
    const { type } = memberGuideShareDataInfo || {}
    const hintText1 = '常来看看'
    const hintText2 = '山姆有与众不同的购物体验'
    const hintText3 = '品质满满的宝藏好物'
    const hintText4 = '期待您的再次探索'

    const hintText5 = '常回来看看'
    const hintText6 = '山姆新奇寻宝之旅等你来开启'

    const hintOpt = {
      fontFamily: 'PingFang SC-Medium, PingFang SC',
      fontSize: 26,
      fontWeight: 500,
      fillStyle: '#4F5355',
      textAlign: 'center',
      x: 375
    }
    // 提示
    if (type === SHARE_DATA_TYPE.MISSING) {
      const hintTexts = [hintText1, hintText2, hintText3, hintText4]
      hintTexts.forEach((text, index) => {
        canvas.drawText(text, {
          ...hintOpt,
          y: 890 + index * 42
        })
      })
    }

    if (type === SHARE_DATA_TYPE.DEFAULT) {
      canvas.drawText(hintText5, {
        ...hintOpt,
        y: 880
      })
      canvas.drawText(hintText6, {
        ...hintOpt,
        y: 950
      })
    }
  }

  //  消费次数
  generateShop(canvas: CreateCanvas) {
    //  购物次数 购物数量
    const shopLabel1 = '共消费了'
    const shopLabel2 = '次，带走了'
    const shopLabel3 = '件商品'
    const shopLabel4 = '带走了'
    const shopLabel5 = '一共消费了'
    const shopLabel6 = '次'

    const shopLabel7 = '其中山姆为您'
    const shopLabel8 = ' 配送到家'

    const shopLabel9 = '曾逛过'
    const shopLabel10 = '次山姆'
    const shopLabel11 = '门店'
    const shopLabel12 = '生活灵感总在逛山姆时怦然闪现'

    const textDefaultOpt = {
      fontFamily: 'PingFang SC-Medium, PingFang SC',
      fillStyle: '#222427',
      fontWeight: 400,
      fontSize: 26
    }

    const numberDefaultOpt = {
      fontSize: 40,
      fillStyle: '#222427',
      fontWeight: 500,
      fontFamily: 'Bogle-Bold, Bogle',
      linePadding: 12
    }

    const categoryOpt = {
      ...textDefaultOpt,
      fontSize: 28,
      fontWeight: 500,
      fillStyle: '#222427',
      x: 375
    }

    const { memberGuideShareDataInfo } = this.shareInfo || {}
    const { orderTotalCount, goodsTotalCount, onlineCount, offlineCount, type } =
      memberGuideShareDataInfo || {}

    // 购物次数 只有 15 16 18 购物次数不在一行
    if (noShopInLine.includes(type)) {
      // 带走了xx 商品
      canvas.drawLineText([
        {
          text: shopLabel4,
          params: {
            ...textDefaultOpt,
            x: 0,
            y: 632
          }
        },
        {
          text: goodsTotalCount,
          params: {
            ...numberDefaultOpt,
            x: 0,
            y: 628 + (isAndroid() ? 8 : 0)
          }
        },
        {
          text: shopLabel3,
          params: {
            ...textDefaultOpt,
            x: 0,
            y: 632
          }
        }
      ])
      // 一共消费了xx次
      canvas.drawLineText([
        {
          text: shopLabel5,
          params: {
            ...textDefaultOpt,
            x: 0,
            y: 692
          }
        },
        {
          text: orderTotalCount,
          params: {
            ...numberDefaultOpt,
            x: 0,
            y: 688 + (isAndroid() ? 8 : 0)
          }
        },
        {
          text: shopLabel6,
          params: {
            ...textDefaultOpt,
            x: 0,
            y: 692
          }
        }
      ])
      // 线上订单
      if (type === SHARE_DATA_TYPE.APPORDER) {
        // 为您配送xx次
        canvas.drawLineText([
          {
            text: shopLabel7,
            params: {
              ...textDefaultOpt,
              x: 0,
              y: 752
            }
          },
          {
            text: shopLabel8,
            params: {
              ...categoryOpt,
              fontSize: 26,
              x: 0,
              y: 752
            }
          },
          {
            text: `${onlineCount}`,
            params: {
              ...numberDefaultOpt,
              x: 0,
              y: 748 + (isAndroid() ? 8 : 0)
            }
          },
          {
            text: shopLabel6,
            params: {
              ...textDefaultOpt,
              x: 0,
              y: 752
            }
          }
        ])
      }
      // 线下订单
      if (type === SHARE_DATA_TYPE.STORTORDER) {
        // 逛过xx次门店
        canvas.drawLineText([
          {
            text: shopLabel9,
            params: {
              ...textDefaultOpt,
              x: 0,
              y: 752
            }
          },
          {
            text: `${offlineCount}`,
            params: {
              ...numberDefaultOpt,
              x: 0,
              y: 748 + (isAndroid() ? 8 : 0)
            }
          },
          {
            text: shopLabel10,
            params: {
              ...textDefaultOpt,
              x: 0,
              y: 752
            }
          },
          {
            text: shopLabel11,
            params: {
              ...categoryOpt,
              fontSize: 26,
              x: 0,
              y: 752
            }
          }
        ])
        // 生活灵感总在逛山姆时怦然闪现
        canvas.drawText(shopLabel12, {
          ...textDefaultOpt,
          textAlign: 'center',
          x: 375,
          y: 812
        })
      }
    } else if (
      [SHARE_DATA_TYPE.ALLCATEGORY, ...saveLableList, SHARE_DATA_TYPE.MISSING].includes(type)
    ) {
      canvas.drawLineText([
        {
          text: shopLabel1,
          params: {
            ...textDefaultOpt,
            x: 0,
            y: 632
          }
        },
        {
          text: `${orderTotalCount}`,
          params: {
            ...numberDefaultOpt,
            x: 0,
            y: 628 + (isAndroid() ? 8 : 0)
          }
        },
        {
          text: shopLabel2,
          params: {
            ...textDefaultOpt,
            x: 0,
            y: 632
          }
        },
        {
          text: `${goodsTotalCount}`,
          params: {
            ...numberDefaultOpt,
            x: 0,
            y: 628 + (isAndroid() ? 8 : 0)
          }
        },
        {
          text: shopLabel3,
          params: {
            ...textDefaultOpt,
            x: 0,
            y: 632
          }
        }
      ])
      type !== SHARE_DATA_TYPE.MISSING &&
        canvas.drawLine({
          x: 108,
          y: 686,
          borderColor: '#667EAF',
          borderWidth: 1,
          lineWidth: 534
        })
    }
  }

  // 类目和省钱明细
  generateMuseAndSave(canvas: CreateCanvas) {
    const { memType } = this.cardInfo
    const { memberGuideShareDataInfo } = this.shareInfo || {}
    const {
      saveMoneyAmount,
      duesMultiple,
      shareCategoryName,
      shareTitle,
      shareCategoryRatio,
      type,
      enumEnable
    } = memberGuideShareDataInfo || {}
    //  省钱金额
    const saveLabel1 = '共计节省'
    const saveLabel2 = '元'
    const saveLabel3 = '相当于'
    const saveLabel4 = `倍 ${memType === 1 ? '普通' : '卓越'}会员年费`

    // 美好灵感
    const museLabel1 = '美好生活灵感来自:'
    const museLabel2 = '在'
    const museLabel3 = '类商品的花费金额占'

    const textDefaultOpt = {
      fontFamily: 'PingFang SC-Medium, PingFang SC',
      fillStyle: '#222427',
      fontWeight: 400,
      fontSize: 26
    }

    const numberDefaultOpt = {
      fontSize: 40,
      fillStyle: '#222427',
      fontWeight: 500,
      fontFamily: 'Bogle-Bold, Bogle',
      linePadding: 12
    }

    const categoryOpt = {
      fontFamily: 'PingFang SC-Medium, PingFang SC',
      fontSize: 28,
      fontWeight: 500,
      fillStyle: '#222427',
      x: 375
    }

    if (saveLableList.includes(type)) {
      canvas.drawLineText([
        {
          text: saveLabel1,
          params: {
            ...textDefaultOpt,
            x: 0,
            y: 724
          }
        },
        {
          text: `${saveMoneyAmount}`,
          params: {
            ...numberDefaultOpt,
            x: 0,
            y: 720 + (isAndroid() ? 8 : 0)
          }
        },
        {
          text: saveLabel2,
          params: {
            ...textDefaultOpt,
            x: 0,
            y: 724
          }
        }
      ])

      type !== SHARE_DATA_TYPE.FAMILY &&
        canvas.drawLineText([
          {
            text: saveLabel3,
            params: {
              ...textDefaultOpt,
              x: 0,
              y: 792
            }
          },
          {
            text: `${duesMultiple}`,
            params: {
              ...numberDefaultOpt,
              x: 0,
              y: 788 + (isAndroid() ? 8 : 0)
            }
          },
          {
            text: saveLabel4,
            params: {
              ...textDefaultOpt,
              x: 0,
              y: 792
            }
          }
        ])
    }
    if (enumEnable && type === SHARE_DATA_TYPE.ALLCATEGORY) {
      canvas.drawLineText([
        {
          text: museLabel1,
          params: {
            ...textDefaultOpt,
            x: 0,
            y: 712
          }
        },
        {
          text: shareTitle,
          params: {
            ...categoryOpt,
            linePadding: 12,
            x: 0,
            y: 712
          }
        }
      ])
      canvas.drawLineText([
        {
          text: museLabel2,
          params: {
            ...textDefaultOpt,
            x: 0,
            y: 772
          }
        },
        {
          text: shareCategoryName,
          params: {
            ...categoryOpt,
            linePadding: 12,
            x: 0,
            y: 772
          }
        },
        {
          text: museLabel3,
          params: {
            ...textDefaultOpt,
            x: 0,
            y: 772
          }
        },
        {
          text: `${Big(shareCategoryRatio || 0)
            .times(100)
            .valueOf()}%`,
          params: {
            ...categoryOpt,
            linePadding: 12,
            x: 0,
            y: 772
          }
        }
      ])
    }
  }

  //  时间 用户名日期
  generateTimeLine(canvas: CreateCanvas) {
    const { memberGuideShareDataInfo } = this.shareInfo || {}
    const { startTime, endTime, membershipDays, nikeName } = memberGuideShareDataInfo || {}
    // 周期时间
    const timeText = `${dayjs(Number(startTime)).format('YYYY年MM月DD日')}-${dayjs(
      Number(endTime)
    ).format('YYYY年MM月DD日')}`
    // 用户名
    const userText = `@${
      nikeName.length > 8 ? `${nikeName.slice(0, 8)}...` : nikeName
    } 与山姆的美好回忆`
    const days = `${membershipDays}`

    const label1 = '在过去的'
    const label2 = '天里'

    const textDefaultOpt = {
      fontFamily: 'PingFang SC-Medium, PingFang SC',
      fillStyle: '#222427',
      fontWeight: 400,
      fontSize: 26
    }

    const numberDefaultOpt = {
      fontSize: 40,
      fillStyle: '#222427',
      fontWeight: 500,
      fontFamily: 'Bogle-Bold, Bogle',
      linePadding: 12
    }

    // 时间
    canvas.drawText(timeText, {
      ...textDefaultOpt,
      fillStyle: '#898E92',
      x: 375,
      y: 442,
      textAlign: 'center'
    })
    // 用户
    canvas.drawText(userText, {
      fontSize: 32,
      fillStyle: '#0165B8',
      fontWeight: 500,
      fontFamily: 'PingFang SC-Medium, PingFang SC',
      x: 375,
      y: 492,
      textAlign: 'center'
    })
    // 相处时间
    canvas.drawLineText([
      {
        text: label1,
        params: {
          ...textDefaultOpt,
          y: 564,
          x: 0
        }
      },
      {
        text: `${days}`,
        params: {
          ...numberDefaultOpt,
          x: 0,
          y: 560 + (isAndroid() ? 8 : 0)
        }
      },
      {
        text: label2,
        params: {
          ...textDefaultOpt,
          x: 0,
          y: 564
        }
      }
    ])
  }

  //  点击分享海报
  handleClickShare(drawCallback: Function) {
    if (!this.canShare) {
      return
    }
    element({
      type: ElementType.Tap,
      elementId: 'click_share_report',
      eventName: 'sam_h5_personalCenter_click_share_report_tap',
      pageType: '个人中心页"',
      pageTitle: '会员指南详情',
      pageId: 'default'
    })
    this.setCanShare(false)
    sr.app.invoke('showLoading', {
      showLoading: true
    })
    const canvas = new CreateCanvas({
      width: 750,
      height: 1624,
      backgroundColor: '#fff'
    })
    // 背景图
    canvas.drawImage(ShareBg, {
      x: 0,
      y: 0,
      width: 1125 * scale,
      height: 2436 * scale
    })

    this.generateTimeLine(canvas)

    this.generateShop(canvas)

    this.generateMuseAndSave(canvas)

    this.generateShareImage(canvas)

    this.generateHint(canvas)

    canvas.toBase64(drawCallback)
  }
}

export default new MemberGuideStore()
