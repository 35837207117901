// 插入经纬度参数
import { IInterceptor } from '@tencent/retailwe-common-libs-http-client'
import { getDeliveryAddressLocation } from '@/common/jsBridge/jsBridgeApi'

export const locationApiList: string[] = []
// 经纬度
export default function locationHandler(): IInterceptor {
  return {
    name: 'locationHandler',
    request: async (request) => {
      console.log(request, request.url)
      if (request.url && locationApiList.includes(request.url)) {
        const location = await getDeliveryAddressLocation()
        if (!request.body) {
          request.body = {}
        }
        if (location.latitude === 0 && location.longitude === 0) return request
        request.headers['latitude'] = location.latitude
        request.headers['longitude'] = location.longitude
      }
      return request
    }
  }
}
