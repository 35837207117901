// 体验卡领取
import { RESPONSE_CODE, ExpCardReceiveDataParamsType, ExpCardStatusParamType } from '@/config'
import { makeAutoObservable } from 'mobx'
import { apis } from '@/common/http-client'
import { Toast } from '@/components/Toast'
import { MessageDecrypt } from '@/utils'

/** 我的报名 0可分享  1邀请成功 2已取消 */
export enum STATUS_MAP {
  /** 活动正常 */
  NORMAL = 0,
  /** 您已经是山姆会员 */
  IS_MEMBBER = 1,
  /** 已经领取体验卡 */
  IS_RECEIVE = 2,
  /** 不符合规则 */
  UNRELU = 3,
  /** 已被抢走 */
  IS_OUT = 4,
  /** 链接已失效 */
  IS_LOSE = 5,
  /** 已过期 */
  IS_EXPIRE = 6,
  /** 活动异常 */
  IS_ERROR = 7,
  /** 发放完毕 */
  IS_SALE_OUT = 8,
  /** 活动未开始或其他异常状态 */
  OTHER = 9
}

class ExpCardReceiveStore {
  //  体验卡详情数据
  expCardInfo = {}

  memberInfo = {}

  expResult = {}

  cardInfo = {}

  isSuccess = false

  ruleInfo = {}

  concatNum = 1

  locationInfo = {
    longitude: '',
    latitude: ''
  }

  activityId: string | string[] = ''

  constructor() {
    // console.log('init store')
    makeAutoObservable(this)
  }

  updateExpCardInfo(values: any) {
    this.expCardInfo = values
  }

  updateLocationInfo(values: any) {
    this.locationInfo = values
  }

  updateConcatNum(values: any) {
    this.concatNum = values
  }

  updateActivityId(value: string | string[]) {
    this.activityId = value
  }

  updateMemberInfo(values: any = {}) {
    this.memberInfo = values
  }

  updateCardInfo(values: any = {}) {
    this.cardInfo = values
  }

  updateSuccess(values: any = {}) {
    this.isSuccess = values
  }

  updateRuleDetail(values: any = {}) {
    this.ruleInfo = values
  }

  updateExperienceCardRuleResponse(values: any = {}) {
    this.expResult = values
  }

  //  查询体验卡领取状态
  async fetchCardStauts(params: ExpCardStatusParamType) {
    try {
      Toast.loading()
      const res = await apis.experience.queryCardStatus({
        ...params,
        localLongitude: this.locationInfo.longitude,
        localLatitude: this.locationInfo.latitude
      })

      const { data, code, msg } = res
      if (code === RESPONSE_CODE.SUCCESS) {
        if (this.concatNum < 3) {
          if (data?.status === 1) {
            Toast.hide()
            this.updateCardInfo({ ...data?.cardInfo } || {})
            this.updateSuccess(true)
          }
          if ([0, 2].includes(data?.status)) {
            setTimeout(() => {
              this.fetchCardStauts(params)
              this.updateConcatNum((this.concatNum += 1))
            }, 1000)
          }
        } else {
          Toast.text(data?.desc || '当前网络异常，请稍后再试')
          this.updateConcatNum(1)
        }
      }
    } catch (e) {
      // 查询失败 也要解除状态
      Toast.text(e?.msg || '当前网络异常，请稍后再试')
      this.updateConcatNum(1)
    }
  }

  //  获取扫码进入页面的体验卡详情
  async queryScanData(params: ExpCardReceiveDataParamsType) {
    try {
      const resp = await apis.experience.fetchScanCheck({
        ...params,
        localLongitude: this.locationInfo.longitude,
        localLatitude: this.locationInfo.latitude
      })
      if (resp.code === RESPONSE_CODE.SUCCESS) {
        this.updateExpCardInfo({
          ...resp.data
        })
      }
    } catch (error: any) {}
  }

  async getScanCard(params: ExpCardReceiveDataParamsType) {
    try {
      let resp = await apis.experience.scanGetCard({
        ...params,
        localLongitude: this.locationInfo.longitude,
        localLatitude: this.locationInfo.latitude
      })
      if (resp.code === RESPONSE_CODE.SUCCESS) {
        //  领取成功
        if (resp?.data?.queryNo) {
          this.fetchCardStauts({
            queryNo: resp.data.queryNo
          })
        } else {
          this.updateExperienceCardRuleResponse({ ...resp.data?.experienceCardRuleResponse } || {})
        }
      }
      return resp.data?.experienceCardRuleResponse?.status || ''
    } catch (error: any) {}
  }

  async getMemberInfo() {
    try {
      const resp = await apis.experience.getMember()
      if (resp.code === RESPONSE_CODE.SUCCESS) {
        if (resp.data?.info) {
          resp.data.info.memName = resp.data.info.memName
            ? MessageDecrypt(resp.data.info.memName)
            : ''
        }
        this.updateMemberInfo({
          ...resp.data?.info
        })
      }
    } catch (error: any) {}
  }

  async getRuleInfo(params: any) {
    try {
      const resp = await apis.experience.ruleDetail({ ...params })
      if (resp.code === RESPONSE_CODE.SUCCESS) {
        this.updateRuleDetail({
          ...resp.data
        })
      }
    } catch (error: any) {}
  }

  async fetchShareCodeInfo(params: any) {
    try {
      const resp = await apis.experience.queryShareCodeInfo({
        ...params,
        localLongitude: this.locationInfo.longitude,
        localLatitude: this.locationInfo.latitude
      })
      if (resp.code === RESPONSE_CODE.SUCCESS) {
        this.updateExpCardInfo({
          ...resp.data
        })
      }
    } catch (error: any) {}
  }

  async getShareCodeCard(params: any) {
    try {
      let resp = await apis.experience.getShareCodeCard({
        ...params,
        localLongitude: this.locationInfo.longitude,
        localLatitude: this.locationInfo.latitude
      })
      if (resp.code === RESPONSE_CODE.SUCCESS) {
        //  领取成功
        if (resp?.data?.queryNo) {
          this.fetchCardStauts({
            queryNo: resp.data.queryNo
          })
        } else {
          this.updateExperienceCardRuleResponse({ ...resp.data?.experienceCardRuleResponse } || {})
        }
      }
      return resp.data?.experienceCardRuleResponse?.status || ''
    } catch (error: any) {}
  }
}

export default new ExpCardReceiveStore()
