export enum GiftCardStatus {
  GENERATED = 0, // 已生成
  ACCEPTED = 1, // 已接受
  REJECTED = 2, // 已拒绝
  EXPIRED = 3, // 已过期
  RECEIVED = 4 // 已领取
}

export enum IdentityEnum {
  RECIPIENTS = 1, // 领取人
  GIVER = 2 // 赠送人
}
export enum IsMemberEnum {
  YES = 1,
  NO = 0
}

export enum MemType {
  NORMAL = 1, // 普通会籍
  PREMIUM = 2 // 卓越会籍
}

export enum IdType {
  IDCARD = 1, // 身份证
  CERTIFICATE = 2, // 港澳来往通行证
  MTP = 3, // 台胞证
  PASSPORT = 4, // 护照
  HKCTZID = 5, // 香港身份证
  MOCTZID = 6 // 澳门身份证
}

// 续费实名接口错误码 待补齐 错误码注释
export enum RenewErrorCode {
  USER_REAL_NAME_ERROR = 'USER_REAL_NAME_ERROR', // 实名错误
  BUY_AGE_NOT_NEED_CONDITION = 'BUY_AGE_NOT_NEED_CONDITION',
  BUY_HAS_EFFECTIVE_CARD_CONDITION = 'BUY_HAS_EFFECTIVE_CARD_CONDITION',
  ID_ARGUMENT_NOT_VALID_ERROR = 'ID_ARGUMENT_NOT_VALID_ERROR',
  REAL_NAME_TIMES_LEFT_TODAY = 'REAL_NAME_TIMES_LEFT_TODAY',
  MAXIMUM_NUMBER_LIMIT_REACHED = 'MAXIMUM_NUMBER_LIMIT_REACHED',
  LIMIT_OF_TIMES_TO_THE_DAY = 'LIMIT_OF_TIMES_TO_THE_DAY',
  SYSTEM_ERROR = 'SYSTEM_ERROR' // 系统错误
}
export enum LoginType {
  FAMILYCARD = 'familyCard', // 亲友卡
  INVITEFRIEND = 'inviteFriend' // 邀请好友
}

// 会员信息
export interface MemInfo {
  autoRenew?: boolean // 是否自动续费

  cardName?: string //     卡类型名称  主卡，亲友免费卡，公司卡，企业副卡配偶，员工卡等

  cardStatus?: number // 会员卡状态   1: 有效期内，2：即将过期，3：已过期，4：已失效

  cardType?: CardType // 卡类型     1:主卡 2:亲友免费卡 3:副卡 4:公司卡 5:企业副卡配偶 6:员工卡 7: 商业主卡

  expireTime?: number // 过期时间

  firstName?: string //     会员名

  headUrl?: string //     头像地址

  joinTime?: number // 入会时间

  lastName?: string //     会员姓

  memCode?: string //     会员码

  memName?: string //     会员姓名

  memType?: number // 会员类别   1:个人会籍，2：卓越会籍

  payChannel?: string //     支付方式

  renewAmount?: string //     续费金额

  uid?: string // 客户ID
}

export enum Status {
  SUCCESS = 'success', // 成功
  FAILD = 'faild' // 失败
}

export enum CardType {
  MAIN = 1, // 主卡
  FREE = 2, // 亲友卡
  SECONDARY = 3, // 副卡
  COMPANY = 4, // 公司卡
  spouse = 5, // 企业副卡配偶
  STAFF = 6, // 员工卡
  BUSINESS_MAIN = 7 // 商业主卡
}

export enum CardStatusType {
  VALID = 1,
  EXPIRE = 2,
  EXPIRED = 3,
  FAILURE = 4,
  FREEZE = 5
}

export interface LifeRightsContent {
  imageUrl: string // 权益图片链接
  imageUrlEn: string // 英文权益图片链接
  jumpLink: string // 跳转链接
  nameCn: string // 中文权益名称
  nameEn: string // 英文权益名称
  status: string // 显示状态：0-显示；1-不显示
  bigImageUrl?: string // 大图权益图片
  bigImageUrlEn?: string // 大图英文权益图片
}
export interface LifeRightsData {
  id: string // 主键id
  mainTitleCn: string // 中文主标题
  mainTitleEn: string // 英文主标题
  subtitleCn?: string // 中文副标题
  subtitleEn?: string // 英文副标题
  equityContent: Array<LifeRightsContent> // 权益内容
}

export interface jumpLinkType {
  appLink?: string
  appPage?: string
  appPageType?: string
  contentDetailId?: string
  h5Link?: string
  h5LiveCode?: string
  linkId?: string
  linkPath?: string
  linkType?: string
  miniContentDetailId?: string
  miniId?: string
  miniLink?: string
  miniPage?: string
  miniPageType?: string
  showMemCode?: string // 外部小程序展示是否透传卡号 0-透传；1-不透传
  memCode?: string
}

export enum RecommendCodeType {
  HAND_IN = 1,
  LINK = 2
}

// AbTest数据定义
export interface AbTestDataProps {
  allEnable?: boolean
  isOpen?: boolean
  group?: string
  versionKey?: string
  bizCode?: string
  paramsJson?: null | string
  strategyDesc?: string
}
