import React, { lazy, Suspense } from 'react'
import Layout, { CommonLayout, H5Layout } from '@/layouts'
import { RouteConfig } from 'react-router-config'

const routesConfig: RouteConfig[] = [
  // APP 路由
  {
    path: '/sams',
    exact: false,
    component: Layout,
    routes: [
      {
        path: '/sams/money-save-calc',
        exact: true,
        component: lazy(() => import('@/pages/moneySaveCalc'))
      },
      {
        path: '/sams/annual-bill', // 新年度账单上线后要把这个删掉，包括对应的静态资源文件夹，太占空间
        exact: true,
        component: lazy(() => import('@/pages/annualBill'))
      },
      {
        path: '/sams/annual-bill-2022',
        exact: true,
        component: lazy(() => import('@/pages/annualBill2022'))
      },
      {
        path: '/sams/annual-bill-2023',
        exact: true,
        component: lazy(() => import('@/pages/annualBill2023'))
      },
      {
        path: '/sams/annual-bill-share',
        exact: true,
        component: lazy(() => import('@/pages/annualBillShare'))
      },
      {
        path: '/sams/saving-plan',
        exact: true,
        component: lazy(() => import('@/pages/savingPlan'))
      },
      {
        path: '/sams/saving-plan-detail',
        exact: true,
        component: lazy(() => import('@/pages/savingPlan/detail'))
      },
      {
        path: '/sams/fill-invoice-detail',
        exact: true,
        component: lazy(() => import('@/pages/fillInvoiceDetail/index'))
      },
      {
        path: '/sams/invoice-order-list',
        exact: true,
        component: lazy(() => import('@/pages/invoiceOrderList/index'))
      },
      {
        path: '/sams/billing-history', // 开票历史
        exact: true,
        component: lazy(() => import('@/pages/invoiceOrderList/billingHistory'))
      },
      {
        path: '/sams/invoice-notes',
        exact: true,
        component: lazy(() => import('@/pages/fillInvoiceDetail/InvoiceNotes'))
      },
      {
        path: '/sams/number-input',
        exact: true,
        component: lazy(() => import('@/pages/fillInvoiceDetail/numberInput'))
      },
      {
        path: '/sams/point-detail',
        component: lazy(() => import('@/pages/pointQuery/index'))
      },
      {
        path: '/sams/autorenew-agreement', // 自动续费协议
        component: lazy(() => import('@/pages/autorenewAgreement'))
      },
      {
        path: '/sams/auto-renew', // 自动续费协议
        component: lazy(() => import('@/pages/autoRenew'))
      },
      {
        path: '/sams/save-money-rule', // 非会员权益-省钱无忧购细则
        component: lazy(() => import('@/pages/saveMoneyRule'))
      },
      {
        path: '/sams/member-task', // 会员任务落地页
        component: lazy(() => import('@/pages/memberTask'))
      },
      {
        path: '/sams/member-prize', // 会员任务奖品列表
        component: lazy(() => import('@/pages/memberTask/memberPrize'))
      },
      {
        path: '/sams/member-receive-info', // 填写自提领取信息
        component: lazy(() => import('@/pages/memberTask/memberReceiveInfo/memberReceiveInfo'))
      },
      {
        path: '/sams/member-mail-info', // 填写邮寄领取信息
        component: lazy(() => import('@/pages/memberTask/memberReceiveInfo/memberMailInfo'))
      },
      {
        path: '/sams/member-receive-succeed', // 实物领取成功页面
        component: lazy(() => import('@/pages/memberTask/memberReceiveInfo/receiveSucceed'))
      },
      {
        path: '/sams/advert/bankcomm', // 交行联名卡落地页
        component: lazy(() => import('@/pages/advert/bankcomm'))
      },
      {
        path: '/sams/advert/bankcomm-detail', // 交行联名卡详情介绍
        component: lazy(() => import('@/pages/advert/bankcomm/detailNew'))
      },
      {
        path: '/sams/advert/pingan', // 平安权益落地页
        component: lazy(() => import('@/pages/advert/pingan'))
      },
      {
        path: '/sams/equity-list', // 我的权益
        component: lazy(() => import('@/pages/equityCenter/equityList'))
      },
      {
        path: '/sams/equity-center', // 权益中心
        component: lazy(() => import('@/pages/equityCenter'))
      },
      {
        path: '/sams/captcha', // 验证码页面
        component: lazy(() => import('@/pages/captcha/index'))
      },
      {
        path: '/sams/invite-activity', // 邀请有礼
        component: lazy(() => import('@/pages/inviteActivity'))
      },
      {
        path: '/sams/cancellation/index', // 注销账号
        component: lazy(() => import('@/pages/cancellation/index'))
      },
      {
        path: '/sams/cancellation/agreement', // 注销协议
        component: lazy(() => import('@/pages/cancellation/agreement'))
      },
      {
        path: '/sams/cancellation/verifyAccount', // 注销验证账号
        component: lazy(() => import('@/pages/cancellation/verifyAccount'))
      },
      {
        path: '/sams/cancellation/result', // 注销结果页
        component: lazy(() => import('@/pages/cancellation/result'))
      },
      {
        path: '/sams/voucher-example', // 全国配--退换货 快递凭证示例
        component: lazy(() => import('@/pages/voucherExample'))
      },
      {
        path: '/sams/voucher-example-goods', // 全国配--退换货 商品凭证示例
        component: lazy(() => import('@/pages/voucherExampleGoods'))
      },
      {
        path: '/sams/voucher-example-english', // 全国配--退换货 快递凭证示例
        component: lazy(() => import('@/pages/voucherExampleEnglish'))
      },
      {
        path: '/sams/voucher-example-goods-english', // 全国配--退换货 商品凭证示例
        component: lazy(() => import('@/pages/voucherExampleGoodsEnglish'))
      },
      {
        path: '/sams/license-page/:spuId?', // 营业许可资质 展示营业执照图片
        component: lazy(() => import('@/pages/licensePage'))
      },
      {
        path: '/sams/experience-card-promote', // 体验卡裂变
        component: lazy(() => import('@/pages/experiencePromote'))
      },
      {
        path: '/sams/experience-card-operate', // 体验卡运营页
        component: lazy(() => import('@/pages/experiencePromote/operate'))
      },
      {
        path: '/sams/experience-card/useRule', // 体验卡规则页
        component: lazy(() => import('@/pages/experiencePromote/useRule'))
      },
      {
        path: '/sams/enterprise-procurement', // 企业采购
        component: lazy(() => import('@/pages/enterpriseProcurement/index'))
      },
      {
        path: '/sams/enterprise-procurement-result', // 企业采购结果页
        component: lazy(() => import('@/pages/enterpriseProcurement/result'))
      },
      {
        path: '/sams/renew', // 续费页
        component: lazy(() => import('@/pages/renew'))
      },
      {
        path: '/sams/activity/maotai', // 茅台活动
        component: lazy(() => import('@/pages/activity/maotai'))
      },
      {
        path: '/sams/offline-activity/list', // 线下活动活动广场
        component: lazy(() => import('@/pages/offlineActivity/AcitityList'))
      },
      {
        path: '/sams/offline-activity/detail', // 线下活动活动详情
        component: lazy(() => import('@/pages/offlineActivity/ActivityDetail'))
      },
      {
        path: '/sams/offline-activity/my', // 线下活动-我的报名
        component: lazy(() => import('@/pages/offlineActivity/MyActivity'))
      },
      {
        path: '/sams/offline-activity/sign', // 线下活动签到
        component: lazy(() => import('@/pages/offlineActivity/SignPage'))
      },
      // 会籍售后申请
      {
        path: '/sams/online-card-refund/index',
        component: lazy(() => import('@/pages/onlineCardRefund/index'))
      },
      // 会籍售后申请  信息页面
      {
        path: '/sams/online-card-refund/refundInfo',
        component: lazy(() => import('@/pages/onlineCardRefund/refundInfo'))
      },
      // 会籍售后申请  提交状态页面
      {
        path: '/sams/online-card-refund/comfirmResult',
        component: lazy(() => import('@/pages/onlineCardRefund/comfirmResult'))
      },
      {
        path: '/sams/renewV2', // 续费页V2.0
        component: lazy(() => import('@/pages/renewV2'))
      },
      {
        path: '/sams/my-experience-card/list', // 我的体验卡
        component: lazy(() => import('@/pages/myExperienceCard/List'))
      },
      {
        path: '/sams/my-experience-card/rule/:id?', // 体验卡活动规则
        component: lazy(() => import('@/pages/myExperienceCard/Rule'))
      },
      {
        path: '/sams/certifiMember', // 实名认证
        component: lazy(() => import('@/pages/certifiMember'))
      },
      {
        path: '/sams/certifiPhone', // 手机认证
        component: lazy(() => import('@/pages/certifiMember/certifiPhone'))
      },
      {
        path: '/sams/equityCompare', // 会员权益对比
        component: lazy(() => import('@/pages/equityCompare'))
      },
      {
        path: '/sams/change-avatar', // 更改会员照片
        component: lazy(() => import('@/pages/changeAvatar'))
      },
      {
        path: '/sams/identity-authentication', // 身份认证
        component: lazy(() => import('@/pages/identityAuthentication'))
      },
      {
        path: '/sams/authentication-detail', // 认证详情
        component: lazy(() => import('@/pages/authenticationDetail'))
      },
      {
        path: '/sams/cmbc-member-succeed', // 升级成功引流民生卡
        component: lazy(() => import('@/pages/advert/cmbc/upgradeSucceed'))
      },
      {
        path: '/sams/self-pickup-map', // 自提地图
        component: lazy(() => import('@/pages/selfPickupMap'))
      },
      {
        path: '/sams/freight-desc', // 运费详情
        component: lazy(() => import('@/pages/freightDesc'))
      },
      {
        path: '/sams/app-update-notic/:id', // app更新
        component: lazy(() => import('@/pages/appUpdate'))
      },
      {
        path: '/sams/jsbridge', // jsbridge
        component: lazy(() => import('@/pages/demo/jsbridge'))
      },
      {
        path: '/sams/member-guide', // 会员指南
        component: lazy(() => import('@/pages/memberGuidePage'))
      },
      {
        path: '/sams/reward-promotion', // 员工推广码中间页
        exact: true,
        component: lazy(() => import('@/pages/rewardPromotion'))
      },
      {
        path: '/sams/member-task-v2', // 会员任务 V2.0
        component: lazy(() => import('@/pages/memberTaskV2/index'))
      },

      {
        path: '/sams/prize-center', // 奖品中心
        component: lazy(() => import('@/pages/memberTaskV2/prizeCenter'))
      },
      {
        path: '/sams/redemption-center', // 兑换中心
        component: lazy(() => import('@/pages/memberTaskV2/redemptionCenter'))
      },
      {
        path: '/sams/my-prize', // 我的奖品
        component: lazy(() => import('@/pages/memberTaskV2/myPrize'))
      },
      {
        path: '/sams/activity-results', // 活动结果
        component: lazy(() => import('@/pages/memberTaskV2/components/ActivityResult'))
      },
      {
        path: '/sams/activity-end', // 活动结束
        component: lazy(() => import('@/pages/memberTaskV2/components/ActivityEnd'))
      },
      {
        path: '/sams/lottery-results', // 抽奖结果
        component: lazy(() => import('@/pages/memberTaskV2/components/LotteryResult'))
      },
      {
        path: '/sams/govSubsidyCertifiMember', // 政府补贴活动实名认证
        component: lazy(() => import('@/pages/govSubsidy/certifiMember'))
      },
      {
        path: '/sams/govSubsidyGetCoupon', // 政府补贴大家电活动领券页
        component: lazy(() => import('@/pages/govSubsidy/getCoupon'))
      },
      {
        path: '/sams/govSubsidyGetCouponV2', // 政府补贴小家电活动领券页
        component: lazy(() => import('@/pages/govSubsidy/getCouponV2'))
      },
      {
        path: '*',
        component: lazy(() => import('@/pages/page404/index'))
      }
    ]
  },
  // H5 相关路由
  {
    path: '/h5',
    component: H5Layout,
    routes: [
      {
        path: '/h5/calc',
        exact: true,
        component: lazy(() => import('@/pages/moneySaveCalc'))
      },
      {
        path: '/h5/family-card',
        component: lazy(() => import('@/pages/familyCard/index'))
      },
      {
        path: '/h5/reg-member',
        component: lazy(() => import('@/pages/regMember/index'))
      },
      {
        path: '/h5/reg-idcard',
        component: lazy(() => import('@/pages/regIdcard/index'))
      },
      {
        path: '/h5/reg-photo',
        component: lazy(() => import('@/pages/regPhoto/index'))
      },
      {
        path: '/h5/buy-member',
        component: lazy(() => import('@/pages/buyMember/index'))
      },
      {
        path: '/h5/error-status',
        component: lazy(() => import('@/pages/errorStatus/index'))
      },
      {
        path: '/h5/address-select',
        component: lazy(() => import('@/pages/addressSelect/index'))
      },
      {
        path: '/h5/member-detail',
        component: lazy(() => import('@/pages/memberDetail/index'))
      },
      {
        path: '/h5/choose-member-type',
        component: lazy(() => import('@/pages/chooseMemberType/index'))
      },
      {
        path: '/h5/pay-result',
        component: lazy(() => import('@/pages/payResult/index'))
      },
      {
        path: '/h5/invite-friend',
        component: lazy(() => import('@/pages/inviteFriendCard/index'))
      },
      {
        path: '/h5/annual-bill',
        exact: true,
        component: lazy(() => import('@/pages/annualBill/WxPage'))
      },
      {
        path: '/h5/annual-bill-2023',
        exact: true,
        component: lazy(() => import('@/pages/annualBill2023/WxPage'))
      },
      // 20240620 下掉低代码旧有页面，迁移到山姆侧自己应用的新地址 https://m-sams.walmartmobile.cn/sitem-ops-web/
      // {
      //   path: '/h5/low-code',
      //   exact: true,
      //   component: lazy(() => import('@/pages/lowCode/index'))
      // },
      {
        path: '*',
        component: lazy(() => import('@/pages/page404/index'))
      }
    ]
  },
  {
    path: '/common',
    component: CommonLayout,
    routes: [
      {
        path: '/common/wx-page-error',
        component: lazy(() => import('@/pages/wxPageError/index'))
      },
      {
        path: '/common/download-app',
        component: lazy(() => import('@/pages/downloadApp'))
      },
      {
        path: '/common/download-hongkong-app',
        component: lazy(() => import('@/pages/downloadApp/hongkongDownload'))
      },
      {
        path: '/common/scan-app',
        component: lazy(() => import('@/pages/downloadApp/appScan'))
      },
      {
        path: '/common/exp-card',
        component: lazy(() => import('@/pages/downloadApp/expCard'))
      },
      {
        path: '/common/help-center/:id?', // 帮助中心
        component: lazy(() => import('@/pages/helpCenter/index'))
      },
      // N倍积分协议
      {
        path: '/common/many-integral-activity/:id?/:language?', // N倍积分协议
        component: lazy(() => import('@/pages/manyIntegralActivity/index'))
      },
      {
        // 协议条例页
        path: '/common/terms-of-agreement',
        component: lazy(() => import('@/pages/termsOfAgreement/index'))
      },
      {
        // 协议条例页
        path: '/common/shop-agreement',
        component: lazy(() => import('@/pages/shopAgreement/index'))
      },
      {
        path: '/common/privacy',
        component: lazy(() => import('@/pages/privacy/index'))
      },
      {
        path: '/common/goods-card', // 商品卡片
        component: lazy(() => import('@/pages/goodsCard/index'))
      },
      {
        path: '/common/transition', // 短信跳链小程序中转页面
        component: lazy(() => import('@/pages/h5Transition/index'))
      },
      {
        path: '/common/customer-service', // 七鱼客服中转页
        component: lazy(() => import('@/pages/customerService/index'))
      },
      {
        path: '/common/exchange', // 兑换码
        component: lazy(() => import('@/pages/exchange/index'))
      },
      {
        path: '/common/offline-share', // 线下活动分享页
        component: lazy(() => import('@/pages/offlineActivity/ShareDetail'))
      },
      {
        path: '/common/experience-card-receive', // 体验卡领取
        component: lazy(() => import('@/pages/experienceCardReceive/index'))
      },
      {
        path: '/common/experience-card-rule', // 体验卡领取规则
        component: lazy(() => import('@/pages/experienceCardReceive/Rule'))
      },
      {
        path: '/common/empty-page', // 缺省页
        component: lazy(() => import('@/pages/emptyPage/index'))
      },
      {
        path: '/common/protocol-list', // 协议列表页
        component: lazy(() => import('@/pages/protocolList/index'))
      },
      {
        path: '/common/equity-offline-details', // 权益下线详情
        component: lazy(() => import('@/pages/equityOfflineDetails'))
      }
    ]
  },
  {
    path: '*',
    component: lazy(() => import('@/pages/page404/index'))
  }
]

export default routesConfig
