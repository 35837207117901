import React, { useEffect } from 'react'
import Cookies from 'js-cookie'
import { getAppDataByNative } from '@/common/jsBridge/nativeApp'
// import { api } from '@/config'
import { apis } from '@/common/http-client'
import { isInApp, isInMiniProgram } from '@/utils'

export function useAddressCode() {
  useEffect(() => {
    if (isInApp()) {
      getAppDataByNative(['addressCode', 'user_id'])
        .then(async (res) => {
          const { addressCode, user_id } = res
          console.log('addressCode', JSON.stringify(addressCode), user_id)
          console.log(addressCode)
          const codeStr = JSON.stringify(addressCode)
          Cookies.set('addressCode', codeStr)
          if (!user_id) return
          const config = await apis.auth.getGrayConfig({
            uid: user_id
          })
          const { addressCode: addressCodeGrayKey } = config?.data?.strategyDetails
          localStorage.setItem('addressCodeGrayKey', JSON.stringify(addressCodeGrayKey))
        })
        .catch((err) => {
          console.error('app版本未提供addressCode')
        })
    } else if (isInMiniProgram()) {
      const appDataStr = sessionStorage.getItem('appData') || '{}'
      try {
        const appData = JSON.parse(appDataStr)
        const { addressCodes, addressCodeGrayKey } = appData || {}
        addressCodes && Cookies.set('addressCode', JSON.stringify(addressCodes))
        localStorage.setItem('addressCodeGrayKey', JSON.stringify(addressCodeGrayKey))
      } catch (e) {}
    }
    // const event = () => {
    //   if (isInApp()) {
    //     getAppDataByNative(['addressCode'])
    //       .then((address) => {
    //         const codeStr = JSON.stringify(address)
    //         Cookies.set('addressCode', codeStr)
    //       })
    //       .catch((err) => {
    //         console.error('app版本未提供addressCode')
    //       })
    //   }
    // }
    // document.addEventListener('visibilitychange', event)
    // return () => {
    //   document.removeEventListener('visibilitychange', event)
    // }
  }, [])
}
