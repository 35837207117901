export const ENV_CONFIG_KEY = 'envConfig'
export const AUTH_KEY_NAME = 'auth-token'
export const UID_KEY = 'uid'
export const H5_KEY = 'h5-key'
export const STORE_KEY = 'store'
export const LAN_KEY = 'system-language'
export const EXT_LAN_KEY = 'language' // 多语言翻译新增字段
export const SAMS_APP_AGENT = 'SR-SAMS'
export const WE_CHAT_AGENT = 'MicroMessenger'
export const APP_VERSION_KEY = 'app-version'
export const OPENID = 'openid'
export const DEVICE_KEY = 'device-type'
export const TVERSION_KEY = 'tversion'
export const REQUEST_CHANNEL_SOURCE = 'rcs'
export const CASH_KEY = 'isCash'
export const REQUEST_JS_VERSION = 'js-version'
// 授权之前，写入本地的回跳 URL cookie
export const AUTH_BACK_URL = 'backUrl'
export const IMAGE_SERVER_DOMAIN = 'myqcloud.com' // 图片服务的域名
export const APP_SCHEME = 'sams://'
export const APP_SCHEME_LINK = `${APP_SCHEME}samsDiscoverDecoration?urlLink=`
export const APP_SCHEME_H5 = `${APP_SCHEME}h5?url=`
export const ANNUAL_BILL_H5_URL = `${import.meta.env.VITE_DOMAIN}/sams/annual-bill-2023` // 年度账单H5链接
export const ANNUAL_BILL_APP_URL = `sams://h5?url=${encodeURIComponent(
  ANNUAL_BILL_H5_URL
)}&isFullScreen=1` // 年度账单APP链接
export const LOTTIE_STATIC_ASSETS_PATH =
  'https://sam-retail-static-1302115363.file.myqcloud.com/lottie/' // lottie 静态资源路径

export const RESPONSE_CODE = {
  SUCCESS: 'Success',
  FAIL: 'Fail',
  AUTH_FAIL: 'AUTH_FAIL',
  NOT_COUPON: 'OUT_OF_COUPONS', // 优惠券库存不足
  CANNOT_COUPON: 'COUPON_HAS_REACHED_THE_MAXIMUM', // 优惠券领取次数到上线
  SEND_COUPON_ERROR: 'SEND_COUPON_ERROR', // 优惠券发送失败
  HAS_SAVE_PRODUCT_ADDRESS: 'HAS_SAVE_PRODUCT_ADDRESS', // 已经保存地址 重复提交
  ORDER_INFORMATION_HAS_CHANGED_ERROR: 'ORDER_INFORMATION_HAS_CHANGED_ERROR', // 续费金额有变
  EXISTS_CARD_ERROR: 'EXISTS_CARD_ERROR', // 有其他卡
  BUY_HAS_EFFECTIVE_CARD_CONDITION: 'BUY_HAS_EFFECTIVE_CARD_CONDITION',
  UN_REAL_NAME_ERROR: 'UN_REAL_NAME_ERROR', // 未实名
  EXIST_WISHCARD_ORDER_DOING: 'EXIST_WISHCARD_ORDER_DOING', // 有未注销亲友卡
  EXIST_TRADE_ORDER_DOING: 'EXIST_TRADE_ORDER_DOING', // 注销失败提示码
  MEMBERSHIP_NO_WRITE_OFF: 'MEMBERSHIP_NO_WRITE_OFF', // 有未注销会员卡
  BUSINESS_MEMBER_NO_WRITE_OFF: 'BUSINESS_MEMBER_NO_WRITE_OFF', // 商业卡注销
  FRIEND_CARD_RESHARE_NOT_ALLOWED: 'FRIEND_CARD_RESHARE_NOT_ALLOWED', // 亲友卡最近一次激活满365天后才可重新分享亲友卡
  FRIEND_CARD_REUSE_TIME_LIMIT: 'FRIEND_CARD_REUSE_TIME_LIMIT', // 主卡分享亲友卡最近一次激活距今未满365天，无法重新领取
  MEMBERSHIP_CARD_STATUS_ABNORMAL_UNBLOCK_NO: 'MEMBERSHIP_CARD_STATUS_ABNORMAL_UNBLOCK_NO', // 卡永久冻结
  MEMBERSHIP_CARD_STATUS_ABNORMAL: 'MEMBERSHIP_CARD_STATUS_ABNORMAL' // 临时冻结
}
export const TRACE_ID = 'treq-id'

/** 默认viewport */
export const baseViewport =
  'width=device-width, initial-scale=1.0,maximum-scale=1.0, user-scalable=0,viewport-fit=cover'
/** 允许3倍放大viewport */
export const scale3Viewport =
  'width=device-width, initial-scale=1.0,maximum-scale=3.0, user-scalable=yes,viewport-fit=cover'

/** 静态资源cos桶地址 */
export const staticBaseUrl = 'https://sam-retail-static-1302115363.file.myqcloud.com/miniapp/'
// 1120全局添加addressCode
export const ADDRESS_CODE = 'addressCode'
export const PROVINCE = 'amapProvinceCode'
export const CITY = 'amapCityCode'
export const DISTRICT = 'amapDistrict'

export enum EnumHttpErrorType {
  System = 1, // request系统错误
  Status = 2, // request status状态码错误
  Code = 3, // request API约定错误码错误
  Custom = 4 // 自定义错误
}

export interface HttpErrorType {
  type?: EnumHttpErrorType // 错误类
  message: string // 显示给用户的异常文本
  icon?: string // 自定义错误图片
  responseText?: string // 错误文本，连续点击五次显示，方便调试
  refreshText?: string // 刷新按钮文本
}

export interface HeaderType {
  [AUTH_KEY_NAME]: string
  [H5_KEY]: string
  [LAN_KEY]?: string
  [EXT_LAN_KEY]?: string
  [DEVICE_KEY]?: string
  [REQUEST_CHANNEL_SOURCE]?: string
  [TRACE_ID]?: string
  [APP_VERSION_KEY]?: string
  [PROVINCE]?: string
  [CITY]?: string
  [DISTRICT]?: string
  [REQUEST_JS_VERSION]?: string
}

export enum PayResultEnum {
  Success,
  Payerror,
  Fail
}
export enum PayTypeEnum {
  Renew = 1, // 续费
  Update // 升级
}
// 0续费 1,卓越续费 2.购买会员，3升级
export enum PayForTypeEnum {
  Renew = 0,
  Great_renew = 1,
  Member = 2,
  Update = 3
}
export enum RenewTypeTitle {
  '普卡连续包年' = 1,
  '普卡年费' = 2,
  '卓越连续包年' = 3,
  '卓越年费' = 4
}
export enum RenewTypeEnum {
  Auto_renew = 1,
  Renew = 2,
  Auto_grent_renew = 3,
  Grent_renew = 4,
  Alone_Sign_Plus = 5
}

// 会员卡状态
export enum CardStatusEnum {
  NORMAL = 1, // 有效期内
  EXPIRING_SOON = 2, // 即将过期
  EXPIRED = 3, // 已过期
  INVALIDATED = 4, // 已失效
  FROZEN = 5 // 已冻结
}

// 会员卡状态(配合国际化 t()使用)
export enum CardStatusTextEnum {
  'common:effective' = CardStatusEnum.NORMAL,
  'common:expiringSoon',
  'common:expired',
  'common:failure',
  'common:freeze'
}

export interface RenewInfo {
  deductAmount: number | string
  renewAmount: number | string
  renewType: number
  cornerMark?: string
  cashAmount?: number
  autoRenew?: boolean
  reportText?: string
  memCardTypeText?: string
}
export enum TitleTypeEnum {
  PERSONAL = 1, // 个人
  COMPANY = 2 // 公司
}

// 对应开票页面的两个 tab
export enum InvoiceTypeEnum {
  ORDINARY = 1, // 普通发票
  VAT = 2 // 增值税发票
}

// 开票操作类型枚举
export enum OperateTypeEnum {
  DEFAULT = 0, // 普通开票
  REOPEN = 1, // 重开发票
  CHANGE = 2, // 换开发票
  CANCEL = 3 // 发票作废
}

export enum MemTypeEnum {
  '个人会籍' = 1,
  '卓越会籍' = 2
}
export enum CardTypeEnum {
  '主卡' = 1,
  '亲友免费卡' = 2,
  '副卡' = 3,
  '公司卡' = 4,
  '企业副卡配偶' = 5,
  '员工卡' = 6,
  '商业主卡' = 7
}

export enum LinkType {
  SYSTEM = 'system', // 跳转到系统链接
  MINIAPP = 'external-app-mini', // 跳转到外部小程序
  H5 = 'external-h5', // 跳转到h5
  DESIGN = 'page-design', // 跳转到装修页
  DISCOVER = 'design-discover', // 跳转到山姆厨房发现页
  COMMUNITYPAGE = 'discover-community-page', // 山姆发现页
  LIVECODE = 'live-code' // 山姆发现页
}

// 广告相关
export interface AdvertiseItem {
  id: string | number
  saasId: string
  headImg: string
  name: string
  miniJumpLink?: any
  h5JumpLink?: any
  miniJumpLinkType?: string
  linkType?: LinkType
  adgroupSign: string
}
interface AdvertiseData {
  adgroupDataList: Array<AdvertiseItem>
  saasId: string | null
}

export interface AdvertiseResponse {
  code: string
  msg?: any
  data: AdvertiseData
}
export enum InvoiceChannelEnum {
  STORE = 1, // 线下流水号开票，
  ONLINE = 0 // 线上订单开票
}

export enum OrderTypeEnum {
  STORE = 1, // 线下订单
  ONLINE = 0 // 线上订单
}
// 开发票的动作类型
export enum InvoiceActionType {
  UPDATE = 'update', // 修改发票
  BATCH = 'batch', // 批量申请发票
  APPLY = 'apply', // 申请发票
  ORDER = 'order' // 确认订单时填写发票信息
}

// 发票开票状态类型 0 开票中  30 已开票  40开票失败
// 1开票中 2开票成功 3开票失败 4重开发票中 5重开成功 6重开失败 7换开发票中 8换开成功 9换开失败
export enum InvoiceStatusType {
  PREPARE = 1,
  FINISHED = 2,
  FAILURE = 3,
  REINVOICEING = 4,
  REINVOICESUCCESS = 5,
  REINVOICEFAIL = 6,
  INVOICECHANGEING = 7,
  INVOICECHANGESUCCESS = 8,
  INVOICECHANGEFAIL = 9,
  STOREFINISHED = 10, // 门店已开票
  INVOICECANCEL = 11 // 已作废
}

export interface OrderChannelList {
  orderNo: String // 订单号
  samsChannel?: String // 山姆订单来源渠道
}

export enum OrderStatusEnum {
  WAITPAY = 5, // 待支付
  WAITDELIVERY = 10, // 待发货
  WAITHARVEST = 40, // 待收货
  WAITPICKUP = 43, // 待取货
  FINISH = 60, // 已完成
  CANCEL = 80 // 已取消
}
export enum TaskStatusEnum {
  AWAITINGACTIVATION = -10, // 任务等待领取，后台不返回这个状态，前端根据IsNeedDraw=1 && TaskState=-1 来判断
  NOTOPENED = -1, // 未开启
  TOCOMPLETE = 0, // 去完成
  TASKRECEIVED = 10, // 已领取了任务，可以去完成
  CLAIM = 50, // 待领取
  COMPLETED = 100, // 已完成
  EXPIRED = 200 // 已过期
}
// 会员任务ui配置
export interface TaskUIConfig {
  // 0512新增字段
  guideImg: string // 引导图
  progressColor: string // 进度条颜色
  progressBgColor: string // 进度条背景色
  sealPopupBgImg: string // 领取印章弹窗背景
  lotteryPopupSwitch: boolean // 是否开启开奖动效
  lotteryEffectImg: string // 开奖动效图

  mainVisual: string // 主视觉图
  mainVisualGif?: string // 主视觉图动效
  stageBgColor: string // 阶段背景颜色 -非选中状态
  stageSelectedBgColor: string //  阶段背景颜色 -选中状态
  stageFontColor: string // 阶段文字颜色
  taskPoolBg: string // 任务池背景图片
  taskPoolTitleColor: string // 任务池标题文案颜色
  taskPoolFontColor: string // 任务池文案颜色
  taskListBgColor: string // 任务列表背景颜色
  btnToCompletedImg: string // 任务按钮图片-待完成
  btnToCompletedColor: string // 任务按钮-待完成 -文字颜色
  btnReceiveImg: string // 任务按钮图片-领取
  btnReceiveColor: string // 任务按钮-领取 -文字颜色
  sealPopupBtnImg: string // 领取印章弹窗按钮图片
  sealPopupBtnColor: string // 领取印章弹窗按钮文案颜色
  sealPopupBtnText: string // 领取印章弹窗按钮文案
  sealPopupFontColor: string // 领取印章弹窗文案颜色
  couponPopupBgImg: string // 优惠券弹窗的背景图片
  couponPopupFontColor: string // 优惠券弹窗文字颜色
  couponPopupBtnColor: string // 优惠券弹窗立即使用文案颜色
  couponPopupLinkColor: string // 优惠券弹窗查看优惠券文案颜色
  stampTurnSwitch: boolean // 是否开启印章翻转
  btnPrizeHintBgColor: string // 领奖提示背景颜色
  btnPrizeHintTextColor: string // 领奖提示文案颜色

  hasExhibit?: string // 是否显示奖励池 '1'-是 ， '0'-否
  awardImg: string // 保底奖励视觉图

  percent100PrizeBgColor: string // 100%保底奖励背景色
  luckyPrizeBgColor: string // 幸运抽奖池背景色
  prizeTitleBgColor: string // 普通奖品标题栏背景色
  prizeTitleTextColor: string // 普通奖品标题文案颜色
  countdownBgColor: string // 倒计时背景颜色
  countdownTextColor: string // 倒计时文字颜色
  lotteryFailImage: string // 谢谢参与奖励图片
  lotteryFailDesc: string // 谢谢参与奖励文案
}
// 未开启、进行中、已完成、已过期
export enum StageStateEnum {
  NOTOPENED = 0, // 未开启
  PROCESSING = 50, // 进行中
  COMPLETED = 110, // 已完成
  EXPIRED = 200 // 已过期
}

// 任务类型
export enum TaskTypeEnum {
  FIRST_ORDER = 1, // 交易类-支付首单
  SINGLE_ORDER_FULL = 2, // 交易类-订单满X元-单笔
  TOTAL_ORDER_FULL = 3, // 交易类-订单满X元-累计
  ORDER_TOTAL_COUNT = 4, // 交易类-订单满X笔
  BUY_SPECIFIED_GOODS = 5, // '交易类-购买指定品类
  OFFLINE_ORDER_COUNT = 6, // 交易类-线下门店下单X件
  CART_ADD_GOODS = 7, // 加车类-购物车SPU数量X个
  ORDER_SHARE = 8, // 互动类-分享
  ORDER_COMMENT = 9, // 互动类-评价
  OPEN_MESSAGE_PUSH = 10, // 信息类-开启PUSH通知
  PERFECT_PERSONAL_INFO = 11, // 信息类-完善个人信息
  BUY_SHARE_GOODS = 12, // 交易类-购买指定商品分享
  BUY_ASSIGN_GOODS = 13, // 交易类-购买指定商品
  INTERACT_BROWSE = 14, // 互动类-页面浏览
  BEHAVIOR_FRIENDS = 15, // 行为类-绑定亲友卡
  BEHAVIOR_PUBLIC = 16, // 行为类-关注公众号
  SHARE_FRIENDS = 17, // 行为类-分享亲友卡
  INTERACT_LIKES = 18, // 互动类-点赞条数
  JOIN_ACTIVITY = 19, // 互动类-参与活动
  INVITE_GIVE_GIFT = 20, // 互动类-邀请有礼
  CART_SHARE_PUBLISH = 21 // 互动类-购物车分享
}

export interface ColorObj {
  hex: string
}
export interface TaskInterface {
  memType?: number // 身份 0 非会员  1会员  2卓越会员
  isNeedDraw?: number // 任务是否需要领取
  stampImg: string // 印章图片
  taskId: string
  activityTaskId: string // 任务唯一id
  taskName: string // 任务名称
  taskType: TaskTypeEnum // 任务类型
  taskProgress: string // 任务进度
  taskNote: string // 任务说明
  taskState: TaskStatusEnum // 任务状态
  taskReceiveState: boolean // 是否已经领取了印章
  taskLink: string // 跳转链接
  taskButtonName: string // 去完成的按钮文案
  pageViewCurrentTime: number // 页面已经浏览时间
  pageViewLimitTime: number // 页面浏览总时间
  pageCounts: number // 页面浏览次数
  stampTurnImg?: string // 印章翻转图片
  stampTurnDesc?: string // 印章翻转说明
  stampTurnButtonText?: string // 印章翻转按钮文案
  stampTurnButtonImg?: string // 印章翻转按钮图片
  stampTurnButtonTextColor?: ColorObj // 印章翻转按钮文案颜色
  stampTurnButtonTargetLink?: string // 印章翻转按钮跳转链接
  taskAbbreviation: string // 缩略标题
  extendActivityId?: string // 邀请有礼ID
}
// 阶段配置
export interface Stage {
  stageId: string // id
  stageName: string // 名称
  priority?: number // 阶段优先级
  beginTime?: string // 结束时间
  endTime?: string // 开始时间
  stageState: StageStateEnum // 阶段状态
  stageDesc: string // 任务阶段说明
  stageAmount: number // 优惠券总额 分
  completeTaskNum?: number // 已完成的任务数量
  activityTasks: TaskInterface[]
}

// 奖品信息
export interface PrizePool {
  minimumPrizes: any[]
  randomPrizes: any[]
}

// 任务配置
export interface TaskConfig {
  activityId: string
  activityName: string
  activityStages: Stage[]
  activityRuleId?: number // 规则id
  pageConfigInfo?: any // 页面配置
  memType?: number
  prizePool: PrizePool
  beginTime: string
  endTime: string
}
// 未中奖配置
export interface LotteryFailInfo {
  lotteryFail: boolean
  image: string
  desc: string
}

// 体验卡请求参数
export interface ExpCardReceiveDataParamsType {
  activityId: string
  activityType: string
  activityName: string
  experienceCardRuleId: number | string
  channelId: string
  storeInfoVOS: string[]
}

export interface ExpCardStatusParamType {
  queryNo: string
}

export interface TaskLink {
  linkType: LinkType
  appLink: string
  appPage: string
  appPageType: string
  h5Link: string
}
export interface ProductPrizeInfo {
  addressCheckInfo: string // 填写禁用字段
  addressDetail: any // 地址信息
  isProductPrize: boolean // 是否有实物奖励
  storeIds: any[] // 门店id
}

// 优惠券信息
export interface CouponData {
  conditionValue: number // 满xx可用（单位分）
  amount: number // 面额（单位分）或者折扣等
  prizeName: string // 优惠券名称
  expireEnd: number // 有效期结束时间
  expireStart: number // 有效期开始时间
  writeOffChannelDescId: ChannelType // 核销渠道类型
  prizeType: PrizeType // 优惠券类型
  image?: string // 奖品图片
  desc?: string // 奖品说明
  asstType?: string // 奖品类型 product-实物 coupon-优惠券
}
// 核销渠道类型
export enum ChannelType {
  ALL = 1, // 全渠道
  APPANDMINIAPP = 2, // APP/小程序
  APPANDOFFLINE = 3, // app/线下门店
  MINIAPPANDOFFLINE = 4, // 小程序/线下门店
  APP = 5, // app
  MINIAPP = 6, // 小程序
  OFFLINE = 7 // 线下门店
}

// 渠道
export const channelTypeMap = {
  [Number(`${ChannelType.ALL}`)]: 'memberTask:coupons.allUse',
  [Number(`${ChannelType.APPANDMINIAPP}`)]: 'memberTask:coupons.appUse',
  [Number(`${ChannelType.APPANDOFFLINE}`)]: 'memberTask:coupons.appadoffline',
  [Number(`${ChannelType.MINIAPPANDOFFLINE}`)]: 'memberTask:coupons.miniappandoffline',
  [Number(`${ChannelType.APP}`)]: 'memberTask:coupons.onlyApp',
  [Number(`${ChannelType.MINIAPP}`)]: 'memberTask:coupons.miniApp',
  [Number(`${ChannelType.OFFLINE}`)]: 'memberTask:coupons.offline'
}

// 关闭印章弹窗以后的操作
export enum CloseSealPopupNextStepEnum {
  NONE = 'none', // 无操作
  COUPON = 'open_coupon', // 打开优惠券弹窗
  TIP = 'open_tip' // 打开提示弹窗
}

// push通知是否开启
export enum PushStatusEnum {
  OPEN = '1', // 已开启
  CLOSED = '0' // 未开启
}

export enum QueryStatus {
  LOADING = 'loading', // 请求中
  DONE = 'done' // 请求完成
}

/** 用户网络状态 */
export enum NetStatus {
  /** wifi环境 */
  WIFI = '1',
  /** 移动网络环境 */
  MOBILE = '0'
}

/**
 * 协议类型
 * 1:系统弹窗 | 2:会员章程 | 3:隐私政策
 */
export enum AgreementTypeEnum {
  systemPopup = '1', // 系统弹窗
  membershipCharter = '2', // 会员章程
  privacyPolicy = '3' // 隐私政策
}

// 视频适配方式
export enum AlignTypeEnum {
  TOP = 'top', // 裁剪底部
  CENTER = 'center', // 居中显示
  BOTTOM = 'bottom' // 裁剪头部
}
// 续费类型
export enum RenewTypeEnum {
  GENERAL_AUTO_RENEW = 1, // 普卡连续包年
  GENERAL_RENEW = 2, // 普卡年费
  PLUS_AUTO_RENEW = 3, // 卓越连续包年
  PLUS_RENEW = 4 // 卓越年费
}

// 续费列表
export interface RenewData {
  cornerMark?: string // 角标文案
  deductAmount: number // 抵扣金额
  originalAmount: number // 原始金额
  payCopy?: string //  支付文案
  renewAmount: number // 续费金额
  renewCopy?: string //  续费文案
  renewType: RenewTypeEnum // 续费类型
}

// 权益状态
export enum EquityStates {
  'received' = 'received',
  'expire' = 'expire',
  'used' = 'used'
}
export enum RedeemCodeStateEnum {
  EXPIRE = 'expire', // 已过期
  RECEIVED = 'received', // 已领取
  USED = 'used' // 已使用
}

export enum PrizeType {
  RANDOM = 1, // 随机奖励 惊喜奖
  MINIMUM = 2 // 保底奖励
}

export enum SystemLanguage {
  CHINESE = 'CN',
  ENGLISH = 'EN',
  TRADITIONAL_CHINESE = 'TC'
}

export enum RenewAgreementType {
  RULES = 'Rules', // 山姆会员章程
  RENEW = 'Renew' // 自动续费服务协议
}
